import React, { useEffect } from 'react'

const Survey = () => {
  
    useEffect(() => {
        if (window) {
            window.location.replace("https://forms.gle/3n4jQAR8iHWh4rii8");
        }
    }, [window])
    return (
        <>
        </>
    )
}
export default Survey
